// This file contains variables used to create other environment variables.
// These variables are the same for all environments.

import { envBase as myRaisifyEnvBase } from './environment.base';

// TODO: update with 4GR domain when setup
const cdnUrl = 'https://assets.huterra.com/';

export const envBase = {
  ...myRaisifyEnvBase,
  brandName: '4GOODRewards',
  appName: '4GOODRewards',
  /**
   * Tagline postfixed to the webpage title by default in {@link TitleService}.
   * For example, "Raisify | Amplify Your Fundraising".
   *
   * Keep in-sync with `$PAGE_TITLE` in `/scripts/app_switch.sh`
   */
  tagline: 'Earn Cash Rewards & Deals',
  domainName: 'app.4goodrewards.com',
  deepLinkUrl: '4goodrewards://app.4goodrewards.com',
  supportEmail: 'customercare@4goodstores.com',
  supportUrl: 'https://4goodstores.helpsite.com/',
  mobileApp: {
    ios: {
      ...myRaisifyEnvBase.mobileApp.ios,
      url: 'https://apps.apple.com/us/app/4goodrewards/id6503178524',
      badge: `${myRaisifyEnvBase.cdnUrl}badge/apple_app_store.png`,
    },
    android: {
      ...myRaisifyEnvBase.mobileApp.android,
      url: 'https://play.google.com/store/apps/details?id=com.fourgoodrewards.mobile',
      badge: `${myRaisifyEnvBase.cdnUrl}badge/google_play.png`,
    }
  }
};
