// This file contains variables used to create other environment variables.
// These variables are the same for all environments.

// TODO: Update with myraisify domain when setup
const cdnUrl = 'https://assets.huterra.com/';

export const envBase = {
  brandName: 'Raisify',
  appName: 'My Raisify',
  /**
   * Tagline postfixed to the webpage title by default in {@link TitleService}.
   * For example, "Raisify | Amplify Your Fundraising".
   *
   * Keep in-sync with `$PAGE_TITLE` in `/scripts/app_switch.sh`
   */
  tagline: 'Amplify Your Fundraising',
  domainName: 'myraisify.com',
  deepLinkUrl: 'myraisify://app.myraisify.com',
  supportEmail: 'customercare@myraisify.com',
  supportUrl: 'https://myraisify.helpsite.com/',
  cdnUrl,
  mobileApp: {
    ios: {
      url: 'https://apps.apple.com/us/app/my-raisify/id6450326136',
      badge: `${cdnUrl}badge/apple_app_store.png`,
      icon: 'apple',
      alt: `Download on the App Store`
    },
    android: {
      url: 'https://play.google.com/store/apps/details?id=com.myraisify.mobile',
      badge: `${cdnUrl}badge/google_play.png`,
      icon: 'android',
      alt: `Get it on Google Play`
    }
  }
};
