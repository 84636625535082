import { Component, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { UserRegistration } from 'app/shared/models/user-registration.model';
import { Logger, AuthService, AnalyticsService } from 'app/core';
import { ActivityType } from 'app/shared/models';
import { FormService } from 'app/core/services/form/form.service';
import { environment } from '../../../../../environments/environment';
import { RecaptchaService } from '../../../recaptcha/recaptcha.service';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
  host: {
    class: 'auth-form auth-form-sign-up'
  }
})
export class SignUpFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() inviteId?: string;

  /**
   * Used to validate a share-invite, if applicable.
   */
  @Input() promo?: string;
  @Input() fundraiserId?: string;
  @Input() teamId?: string;

  @Output() success: EventEmitter<any> = new EventEmitter()
  @Output() error: EventEmitter<any> = new EventEmitter()

  @ViewChild('signUpForm', {static: true}) signUpForm: NgForm;

  public readonly websiteUrl = environment.huterra.website.url;

  public alert: { type: string, msg: string } = {
    type: 'success',
    msg: null
  };

  public model: UserRegistration = {
    provider: 'id-pw',
    firstName: null,
    lastName: null,
    username: null,
    email: null,
    password: null,
    confirmPassword: null,
    zipCode: null,
    city: null,
    state: null,
    agreeToTerms: false,
    recaptchaToken: null,
  }

  public loading = false;

  constructor(public formService: FormService,
              private logger: Logger,
              private auth: AuthService,
              private analytics: AnalyticsService,
              private recaptchaService: RecaptchaService) { }

  private zipChanges$: Subscription

  ngAfterViewInit() {
    setTimeout(() => {
      this.zipChanges$ = this.formService.addZipCodeValidation(this.signUpForm)
    })
  }

  ngOnInit() {
    this.recaptchaService.showBadge();
  }

  ngOnDestroy() {
    if (this.zipChanges$) this.zipChanges$.unsubscribe()
    this.recaptchaService.hideBadge();
  }

  alertClose() {
    this.alert = null
  }

  private validatePassword(password: string) {
    return this.formService.passwordValidation.pattern.test(password);
  }

  public onSubmit(): void {
    this.alert = null
    if (this.validatePassword(this.model.password) === false) {
      this.alert = {
        type: 'danger',
        msg: 'Password must contain upper and lower case letter, one number, and be between 6 and 25 characters long.'
      }
      return
    }
    if (this.model.password !== this.model.confirmPassword) {
      this.alert = {
        type: 'danger',
        msg: 'Passwords do not match'
      }
      return
    }

    this.loading = true;

    this.recaptchaService.execute('signUp')
      .pipe(switchMap(recaptchaToken => {
          return this.auth.register({
            ...this.model,
            recaptchaToken,
            inviteId: this.inviteId,
            promo: this.promo,
            fundraiserId: this.fundraiserId,
            teamId: this.teamId,
          });
        }),
        finalize(() => this.loading = false))
      .subscribe(
        () => {
          this.sendSignupEvent();
          this.success.emit()
        },
        err => {
          this.error.emit(err)
          this.logger.error(err)
          this.alert = {
            type: 'danger',
            msg: err
          }
        }
      )
  }

  private sendSignupEvent(): void {
    let activityType = ActivityType.Signup.SIGNUP;
    this.analytics.sendEvent(activityType);
  }
}
