<div class="card-title text-center">
  <h3>Sign In</h3>
</div>

<mat-horizontal-stepper *ngIf="showStepper(); else formOnly" labelPosition="bottom" linear id="stepper">
  <mat-step label="Login" [completed]="false" editable="false">
    <app-sign-in-form (success)="goToNextStep()"></app-sign-in-form>
    <div class="text-center color-light size-14" *ngIf="!hideSignup">
      Need an account?&nbsp;<a class="app-link" routerLink="/signup" queryParamsHandling="preserve">Sign up</a>
    </div>
  </mat-step>

  <mat-step [label]="stepDescription" [completed]="false" editable="false">
    <app-intermediate-step (success)="goToNextStep()"
                           [options]="{ searchQuery: promo, fundraiserId: fundraiserId, teamId: teamId }">
    </app-intermediate-step>
  </mat-step>

  <mat-step label="Start shopping" [completed]="false" editable="false">
    <app-start-shopping mode="signin"></app-start-shopping>
  </mat-step>
</mat-horizontal-stepper>

<ng-template #formOnly>
  <app-sign-in-form (success)="goToNextStep()"></app-sign-in-form>
  <div class="text-center color-light size-14" *ngIf="!hideSignup">
    Need an account?&nbsp;<a class="app-link" routerLink="/signup" queryParamsHandling="preserve">Sign up</a>
  </div>
</ng-template>
